/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */

import { Dropdown, Menu, Layout } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import {
	DashboardOutlined,
	CarOutlined,
	TeamOutlined,
	EnvironmentOutlined,
	NodeIndexOutlined,
	LogoutOutlined,
	SettingOutlined,
	UserOutlined,
	ApartmentOutlined,
	GlobalOutlined,
	BankOutlined,
	TagsOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { logout } from '../redux/authSlice';
import CustomAvatar from './Global/CustomAvatar/CustomAvatar';
import ImageComponent from './Global/ImageComponent/ImageComponent';
import { getTenantBrand } from '../redux/tenantBrandSlice';
import { TenantFeaturesContext } from '../Context/TenantFeatureContext';

const { Header } = Layout;
const horizontalBarStyle = {
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	backgroundColor: 'white',
	padding: '0 15px',
};

const NavBar = ({ role }) => {
	const { tenantFeatures } = useContext(TenantFeaturesContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [activeMenu, setActiveMenu] = React.useState([]);
	const { auth } = useSelector((state) => state.auth);
	const isMobile = useMediaQuery({ maxWidth: 1170 }); // Define the mobile breakpoint as per your requirement
	const { Brand } = useSelector((state) => state.brand);

	useEffect(() => {
		switch (window.location.pathname) {
			case '/app/main':
				setActiveMenu(['dashboard']);
				break;
			case '/app/main/driver':
				setActiveMenu(['driver']);
				break;
			case '/app/main/rider':
				setActiveMenu(['rider']);
				break;
			case '/app/main/driversVehicles':
				setActiveMenu(['driversVehicles']);
				break;
			case '/app/main/vehiclesType':
				setActiveMenu(['vehicleType']);
				break;
			case '/app/main/vehicles/make':
				setActiveMenu(['make']);
				break;
			case '/app/main/vehicles/model':
				setActiveMenu(['model']);
				break;
			case '/app/main/location':
				setActiveMenu(['geoFenceLocations']);
				break;
			case '/app/main/restrictedArea':
				setActiveMenu(['restrictedArea']);
				break;
			case '/app/main/km-range-fare':
				setActiveMenu(['km-range-fare']);
				break;
			case '/app/main/location-wise-fare':
				setActiveMenu(['location-wise-fare']);
				break;
			case '/app/main/settings':
				setActiveMenu(['settings']);
				break;
			case '/app/main/booking':
				setActiveMenu(['private-bookings']);
				break;
			case '/app/main/booking-pool':
				setActiveMenu(['shared-bookings']);
				break;
			case '/app/main/refunds':
				setActiveMenu(['refunds']);
				break;
			case '/app/main/fleet-operators':
				setActiveMenu(['fleet-operators']);
				break;
			case '/app/main/way-points':
				setActiveMenu(['way-points']);
				break;
			case '/app/main/gods-view':
				setActiveMenu(['gods-view']);
				break;
			case '/app/main/corporates':
				setActiveMenu(['corporates']);
				break;
			case '/app/main/bundles':
				setActiveMenu(['bundles']);
				break;
			case '/app/main/reviews':
				setActiveMenu(['reviews']);
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname]);

	useEffect(() => {
		fetchTenantBrand();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleLogout = () => {
		dispatch(logout())
			.unwrap()
			.then(() => navigate(`/app/login`));
	};

	const fetchTenantBrand = () => {
		dispatch(getTenantBrand());
	};

	const style = {
		navItem: {},
	};

	const userProfileMenu = (
		<Menu>
			<Menu.Item key='profile'>
				<Link to='/app/main/profile'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}>
						<UserOutlined />
						<span style={style.navItem}>Profile</span>
					</div>
				</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item onClick={handleLogout} key='logout'>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<LogoutOutlined />
					<span style={style.navItem}>Logout</span>
				</div>
			</Menu.Item>
		</Menu>
	);

	const hasCarPoolingFeature = tenantFeatures.some((f) => f.feature.id === 1);

	const items = [
		{
			label: isMobile ? (
				Brand?.brandData?.webLogo != null ? (
					<ImageComponent size={50} imageId={Brand?.brandData?.webLogo} />
				) : (
					<h1 style={{ color: 'orange', display: 'none' }}>Handson Taxi</h1>
				)
			) : Brand?.brandData?.webLogo != null ? (
				<ImageComponent size={50} imageId={Brand?.brandData?.webLogo} />
			) : (
				<h1 style={{ color: 'orange' }}>Handson Taxi</h1>
			),
			role: ['ADMIN', 'SUPER_ADMIN', 'CORPORATE_ADMIN'],
			show: true,
		},

		{
			label: (
				<Link to='/app/main'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<DashboardOutlined />
						{!isMobile && <span style={style.navItem}>Dashboard</span>}
					</div>
				</Link>
			),
			key: 'dashboard',
			role: ['ADMIN', 'SUPER_ADMIN'],
			show: true,
		},
		{
			label: (
				<Link to='/app/main/fleet-operators'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<ApartmentOutlined />
						{!isMobile && <span style={style.navItem}>Fleet Operators</span>}
					</div>
				</Link>
			),
			key: 'fleet-operators',
			role: ['ADMIN', 'SUPER_ADMIN'],
			show: true,
		},
		{
			label: (
				<Link to='/app/main/driver'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<CarOutlined />
						{!isMobile && <span style={style.navItem}>Drivers</span>}
					</div>
				</Link>
			),
			key: 'driver',
			role: ['ADMIN', 'SUPER_ADMIN'],
			show: true,
		},
		{
			label: (
				<Link to='/app/main/rider'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<TeamOutlined />
						{!isMobile && <span style={style.navItem}>Riders</span>}
					</div>
				</Link>
			),
			key: 'rider',
			role: ['SUPER_ADMIN'],
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<CarOutlined />
					{!isMobile && <span style={style.navItem}>Vehicles</span>}
				</div>
			),
			key: 'vehicles',
			children: [
				{
					label: (
						<Link to='/app/main/driversVehicles'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}>
								<CarOutlined />
								<span style={style.navItem}>Drivers Vehicles</span>
							</div>
						</Link>
					),
					key: 'driversVehicles',
				},
				{
					label: (
						<Link to='/app/main/vehiclesType'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'start',
								}}>
								<CarOutlined />
								<span style={style.navItem}>Vehicles Types</span>
							</div>
						</Link>
					),
					key: 'vehicleType',
				},
				{
					label: (
						<Link to='/app/main/vehicles/make'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'start',
								}}>
								<CarOutlined />
								<span style={style.navItem}>Vehicles Make</span>
							</div>
						</Link>
					),
					key: 'make',
				},
				{
					label: (
						<Link to='/app/main/vehicles/model'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'start',
								}}>
								<CarOutlined />
								<span style={style.navItem}>Vehicles Model</span>
							</div>
						</Link>
					),
					key: 'model',
				},
			],
			role: ['ADMIN', 'SUPER_ADMIN'],
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<EnvironmentOutlined />
					{!isMobile && <span style={style.navItem}>Locations</span>}
				</div>
			),
			key: 'location',
			children: [
				{
					label: (
						<Link to='/app/main/location'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}>
								<EnvironmentOutlined />
								<span style={style.navItem}>Geofence Locations</span>
							</div>
						</Link>
					),
					key: 'geoFenceLocations',
				},
				{
					label: (
						<Link to='/app/main/restrictedArea'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}>
								<EnvironmentOutlined />
								<span style={style.navItem}>Restricted Areas</span>
							</div>
						</Link>
					),
					key: 'restrictedArea',
				},
				{
					label: (
						<Link to='/app/main/km-range-fare'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}>
								<EnvironmentOutlined />
								<span style={style.navItem}>KM Range Fare</span>
							</div>
						</Link>
					),
					key: 'km-range-fare',
				},
				{
					label: (
						<Link to='/app/main/location-wise-fare'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}>
								<EnvironmentOutlined />
								<span style={style.navItem}>Locationwise Fare</span>
							</div>
						</Link>
					),
					key: 'location-wise-fare',
				},
				{
					label: (
						<Link to='/app/main/way-points'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}>
								<EnvironmentOutlined />
								<span style={style.navItem}>Waypoints</span>
							</div>
						</Link>
					),
					key: 'way-points',
				},
			],
			role: ['ADMIN', 'SUPER_ADMIN'],
			show: true,
		},

		{
			label: (
				<Link to='/app/main/gods-view'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<GlobalOutlined />
						{!isMobile && <span style={style.navItem}>God's View</span>}
					</div>
				</Link>
			),
			key: 'gods-view',
			role: ['ADMIN', 'SUPER_ADMIN'],
			show: true,
		},
		{
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<NodeIndexOutlined />
					{!isMobile && <span style={style.navItem}>Bookings</span>}
				</div>
			),
			key: 'booking',

			role: ['ADMIN', 'SUPER_ADMIN', 'CORPORATE_ADMIN'],
			children:
				hasCarPoolingFeature && (role?.includes('SUPER_ADMIN') || role?.includes('ADMIN'))
					? [
							{
								label: (
									<Link to='/app/main/booking'>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}>
											<NodeIndexOutlined />
											<span style={style.navItem}>All Bookings</span>
										</div>
									</Link>
								),
								key: 'private-bookings',
							},
							{
								label: (
									<Link to='/app/main/booking-pool'>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}>
											<NodeIndexOutlined />
											<span style={style.navItem}>Booking Pools</span>
										</div>
									</Link>
								),
								key: 'shared-bookings',
							},
							{
								label: (
									<Link to='/app/main/refunds'>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}>
											<NodeIndexOutlined />
											<span style={style.navItem}>Refunds</span>
										</div>
									</Link>
								),
								key: 'refunds',
							},
							{
								label: (
									<Link to='/app/main/reviews'>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}>
											<NodeIndexOutlined />
											<span style={style.navItem}>Reviews</span>
										</div>
									</Link>
								),
								key: 'reviews',
							},
					  ]
					: [
							{
								label: (
									<Link to='/app/main/booking'>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}>
											<NodeIndexOutlined />
											<span style={style.navItem}>All Bookings</span>
										</div>
									</Link>
								),
								key: 'private-bookings',
							},
					  ],
			show: true,
		},
		{
			label: (
				<Link to='/app/main/settings'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<SettingOutlined />
						{!isMobile && <span style={style.navItem}>Settings</span>}
					</div>
				</Link>
			),
			key: 'settings',
			role: ['ADMIN', 'SUPER_ADMIN'],
			show: true,
		},
		{
			label: (
				<Link to='/app/main/corporates'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<BankOutlined />
						{!isMobile && <span style={style.navItem}>Corporates</span>}
					</div>
				</Link>
			),
			key: 'corporates',
			role: ['SUPER_ADMIN', 'CORPORATE_ADMIN'],
			show: true,
		},
		{
			label: (
				<Link to='/app/main/bundles'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<TagsOutlined />
						{!isMobile && <span style={style.navItem}>Bundles</span>}
					</div>
				</Link>
			),
			key: 'bundles',
			role: ['SUPER_ADMIN'],
			show: true,
		},
	];

	// Filter the items based on the role state array
	const filteredItems = items.filter((item) => {
		// If any role from the auth state exists in the item's 'role' array, keep the item
		return auth?.profile?.role?.some((UserRole) => item.role?.includes(UserRole)) && item.show;
	});

	return (
		<Header id='navbar' style={horizontalBarStyle}>
			<Menu
				mode='horizontal'
				items={filteredItems}
				style={{
					display: 'flex',
					alignItems: 'center',
					height: '99%',
					width: '80vw',
				}}
				selectedKeys={activeMenu}
			/>
			<div style={{ flex: 1 }} />

			<Dropdown overlay={userProfileMenu}>
				<span
					style={{
						marginRight: '10px',
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					{auth?.profile?.profilePicture != null ? (
						<ImageComponent size={35} imageId={auth?.profile?.profilePicture} />
					) : (
						<CustomAvatar
							name={`${auth?.profile?.name} ${auth?.profile?.surname}`}
							type='initials'
						/>
					)}
					{!isMobile && (
						<span style={style.navItem}>
							{`${auth?.profile?.name} ${auth?.profile?.surname}`}
						</span>
					)}
				</span>
			</Dropdown>
		</Header>
	);
};

export default NavBar;
