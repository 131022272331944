/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Breadcrumb, Button, Card, Input, Tooltip, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { style } from '../../Styles';
import CustomTableComponents from '../Global/CustomTableComponents';
import {
	changeFleetOperatorStatus,
	deleteFleetOperator,
	getAllFleetOperators,
	getAllFleetOperatorsByAdmin,
} from '../../redux/fleetOperatorSlice';
import FleetOperatorDrawer from './FleetOperatorDrawer';
import { formatEnumValue } from '../../Utils/ArrayToObject';
import ActionButtonWithChangeStatus from '../Global/ActionButtonWithChangeStatus';

const FleetOperator = () => {
	const [makingApiCall, setMakingApiCall] = useState(false);
	const [filter, setFilter] = useState('');
	const { fleetOperator } = useSelector((state) => state.fleetOperator);
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [selectedFleet, setSelectedFleet] = useState(null);
	const { auth } = useSelector((state) => state.auth);

	const showDrawer = () => {
		setDisplayDrawer(true);
	};
	const onCloseDrawer = () => {
		setDisplayDrawer(false);
		setSelectedFleet(null);
	};
	const dispatch = useDispatch();
	const data = fleetOperator?.fleetOperatorList
		? Object.values(fleetOperator.fleetOperatorList)
		: [];
	const filtredData = data.filter((item) => {
		const itemLowerCase = (str) => (str ? str.toLowerCase() : '');

		return (
			itemLowerCase(item?.name)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.type)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.vatNumber)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.idCardNumber)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.contactName)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.contactSurname)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.contactEmail)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.contactPhone)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.accountHolderName)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.iban)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.bankName)?.includes(filter?.toLowerCase()) ||
			itemLowerCase(item?.bicSwiftCode)?.includes(filter?.toLowerCase())
		);
	});

	const fetchAllFlletOperators = () => {
		setMakingApiCall(true);
		const isSuperAdmin = auth?.profile?.role?.includes('SUPER_ADMIN');
		if (!isSuperAdmin) {
			dispatch(getAllFleetOperatorsByAdmin())
				.unwrap()
				.then(() => {})
				.catch(() => {
					message.error('Error getting fleet operators');
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		} else {
			dispatch(getAllFleetOperators())
				.unwrap()
				.then(() => {})
				.catch(() => {
					message.error('Error getting fleet operators');
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		}
	};

	useEffect(() => {
		fetchAllFlletOperators();
	}, [dispatch]);

	const handleDelete = (record, calbback) => {
		setMakingApiCall(true);
		// check if the fleet operator is not missing any required fields
		if (
			record?.vatNumber &&
			record?.contactName &&
			record?.contactSurname &&
			record?.contactEmail &&
			record?.contactPhone
		) {
			dispatch(deleteFleetOperator(record.id))
				.unwrap()
				.then(() => {
					message.success('Fleet operator deleted successfully');
					calbback();
				})
				.catch(() => {})
				.finally(() => {
					onCloseDrawer();
					setMakingApiCall(false);
				});
		} else {
			message.error('Fleet operator is missing required fields');
			setMakingApiCall(false);
		}
	};
	const handleSelectedFleet = (selectedRecord) => {
		setSelectedFleet(selectedRecord);
		showDrawer();
	};
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => a.name.localeCompare(b.name),
			sortDirections: ['descend', 'ascend'],
		},

		{
			title: 'Type',
			key: 'type',
			render: (record) => {
				return (
					<div style={{ textAlign: 'center' }}>
						<span style={{ ...style.tags, textAlign: 'center' }}>{`${formatEnumValue(
							record?.type,
						)} `}</span>
					</div>
				);
			},
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.type.localeCompare(b.type),
		},
		{
			title: 'VAT Number',
			dataIndex: 'vatNumber',
			key: 'vatNumber',
			sorter: (a, b) => a.vatNumber.localeCompare(b.vatNumber),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'ID Card Number',
			dataIndex: 'idCardNumber',
			key: 'idCardNumber',
			sorter: (a, b) =>
				a?.idCardNumber?.toLowerCase().localeCompare(b?.idCardNumber?.toLowerCase()),

			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Contact Name',
			dataIndex: 'contactName',
			key: 'contactName',
			sorter: (a, b) => a.contactName.localeCompare(b.contactName),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Contact Surname',
			dataIndex: 'contactSurname',
			key: 'contactSurname',
			sorter: (a, b) => a.contactSurname.localeCompare(b.contactSurname),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Contact Email',
			dataIndex: 'contactEmail',
			key: 'contactEmail',
			sorter: (a, b) => a.contactEmail.localeCompare(b.contactEmail),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Contact Phone',
			dataIndex: 'contactPhone',
			key: 'contactPhone',
			sorter: (a, b) => a.contactPhone.localeCompare(b.contactPhone),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Account Holder Name',
			dataIndex: 'accountHolderName',
			key: 'accountHolderName',
			sorter: (a, b) => a.accountHolderName.localeCompare(b.accountHolderName),

			sortDirections: ['descend', 'ascend'],
		},
		/* {
			title: 'IBAN',
			dataIndex: 'iban',
			key: 'iban',
			sorter: (a, b) => a.iban.localeCompare(b.iban),
			sortDirections: ['descend', 'ascend'],
		}, */
		{
			title: 'Bank Name',
			dataIndex: 'bankName',
			key: 'bankName',
			sorter: (a, b) => a.bankName.localeCompare(b.bankName),
			sortDirections: ['descend', 'ascend'],
		},
		/* {
			title: 'BIC/SWIFT Code',
			dataIndex: 'bicSwiftCode',
			key: 'bicSwiftCode',
			sorter: (a, b) => a.bicSwiftCode.localeCompare(b.bicSwiftCode),
			sortDirections: ['descend', 'ascend'],
		}, */
		{
			title: 'Status',
			key: 'status',
			render: (record) => {
				return (
					<span style={style.dataContainer}>
						{record.active ? (
							<div style={style.statusActif}>Active</div>
						) : (
							<div style={style.statusInactif}>Inactive</div>
						)}
					</span>
				);
			},
			sorter: {
				compare: (a, b) => a.status - b.status,
				multiple: 1,
			},
			width: 90,
		},
		auth?.profile?.role?.includes('SUPER_ADMIN') ? {
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<ActionButtonWithChangeStatus
					record={record}
					onDelete={() => handleDelete(record, () => {})}
					onEdit={() => handleSelectedFleet(record)}
					onRowClick={() => handleSelectedFleet(record)}
					onChangeStatus={() => handleChangeStatus(record)}
				/>
			),
		}: {}
	];

	const handleChangeStatus = (record) => {
		setMakingApiCall(true);
		// check if the fleet operator is not missing any required fields
		if (
			record?.vatNumber &&
			record?.contactName &&
			record?.contactSurname &&
			record?.contactEmail &&
			record?.contactPhone
		) {
			dispatch(
				changeFleetOperatorStatus({
					id: record.id,
					active: !record.active,
				}),
			)
				.unwrap()
				.then(() => {
					message.success('Fleet Operator status updated successfully');
				})
				.catch(() => {
					message.error('Error updating Fleet Operator status');
				})
				.finally(() => {
					setMakingApiCall(false);
				});
		} else {
			message.error(
				'You cannot activate this Fleet Operator since it is missing some required fields',
			);
			setMakingApiCall(false);
		}
	};

	return (
		<>
			<Card
				style={{
					margin: 16,
					maxHeight: '90%', // Set maximum width
					height: 'auto', // Allow width to be dynamic
				}}>
				<div style={style.inlineContent}>
					<div
						style={{
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							float: 'left',
							marginBottom: 8,
						}}>
						<Breadcrumb
							style={{ margin: '0 3em 0 0', fontSize: '1.1em' }}
							items={[
								{
									title: 'Fleet Operators',
								},
							]}
						/>
						<Button
							type='default'
							size='small'
							// style={{ margin: '0 8px 8px 0' }}
							onClick={() => fetchAllFlletOperators()}>
							<ReloadOutlined />
						</Button>
					</div>
					<div
						style={{
							...style.inlineContent,
							float: 'right',
							marginBottom: 8,
						}}>
						<Input.Search
							placeholder='Search'
							style={{ margin: '0 8px 8px 0' }}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
						{
							auth?.profile?.role?.includes('SUPER_ADMIN') && <Tooltip
								title={
									filtredData.some(
										(fo) =>
											!fo?.vatNumber ||
											!fo?.contactName ||
											!fo?.contactSurname,
									)
										? 'Unable to add a fleet operator until all required fields for the existing operator are filled.'
										: 'Create a new Fleet Operator'
								}>
								<Button
									disabled={filtredData.some(
										(fo) =>
											!fo?.vatNumber ||
											!fo?.contactName ||
											!fo?.contactSurname,
									)}
									type='default'
									style={{ float: 'right', marginBottom: 8 }}
									onClick={showDrawer}>
									Add Fleet Operator
								</Button>
							</Tooltip>
						}
					</div>
				</div>
				<CustomTableComponents
					isMakingApiCall={makingApiCall}
					columns={columns}
					dataArray={filtredData}
					handleDoubleClickRow={(record) => auth?.profile?.role?.includes('SUPER_ADMIN') ? handleSelectedFleet(record): {}}
					handleSelectedRow={() => {}}
				/>
			</Card>
			{displayDrawer && (
				<FleetOperatorDrawer
					onClose={onCloseDrawer}
					fleetId={selectedFleet?.id}
					handleDelete={handleDelete}
				/>
			)}
		</>
	);
};

export default FleetOperator;
